$couleur_principale : #fff;
$couleur_secondaire: #3b5998 ;
$couleur_tierce: #888d90;
$couleur_bordures:#ebebeb;

/* Général */
$body_background_color : #f5f5f5;
$content_background_color : #fff;
$texte_lien:$couleur_secondaire;
$texte_lien_hover:lighten($texte_lien,10%);
$header_background:$content_background_color;
$slogan_color_texte:$couleur_secondaire;
$gros_titre_color:#fff;
$gros_titre_background_color:$couleur_secondaire;
$titre_color:#000;
$titre_border_color:$couleur_secondaire;
$texte_color:#000;

/* Menu */
$menu_background_color:#fff;
$nav_background_color:#737373;
$nav_texte_color:#000;
$nav_texte_color_hover:$couleur_secondaire;
$li_background_color:#fff ;
$li_background_color_hover:#fff ;
$icone_color:#fff;
$icone_color_hover:#fff;
$icone_background_color:$couleur_secondaire;
$icone_background_color_hover:$couleur_secondaire;
$li_border_color:$couleur_bordures;

/* Menu responsive */
$burger_background_color:#94999c;
$burger_texte_color:#fff;
$burger_background_color_ouvert:$couleur_secondaire;
$burger_texte_color_ouvert:#fff;

/* Produits */
$mask_background:$couleur_secondaire;
$etiquette_produit_background:$couleur_secondaire;
$etiquette_produit_texte:#fff;
$remise_background_color:$couleur_secondaire;
$remise_texte_color:#fff;
$remise_texte_color_3:#000;
$background_produit:$couleur_principale;
$background_produit_hover:$background_produit;
$border_produit:#ebebeb;
$texte_produits:#000;
$texte_titre_produits:#000;
$triangle_border_right:#fff;

/* Fiche produit */
$fiche_produit_border:$couleur_bordures;
$background_achat:$couleur_secondaire;

/* Slider */
$background_texte:rgba(0,0,0,0.4);
$color_texte_slider:#fff;

/* Galerie photo*/
$galerie_texte_color:#fff;

/* Livre d'or */
$message_lo_color:$couleur_secondaire;

/* Blog / Boutons */
$btn_background_color:$couleur_principale;
$btn_texte_color:#000;

/* Footer */
$footer_background_color: #F2F2F2;
$footer_texte_color:#000;
$footer_link_color:$texte_lien;

/* Colonne de gauche */
$colonne_gauche_border:$couleur_principale;

/* Couleur des boutons */

$button_background:$couleur_secondaire;
$button_border:$couleur_secondaire;
$button_color:#fff;
$button_background_hover:#fff;
$button_color_hover:$couleur_secondaire;
$button_border_hover:#fff;

$arrow_background:$button_background;
$arrow_background_hover:$button_color;
$arrow_color:$button_background_hover;
$arrow_color_hover:$button_color_hover;

$background_addbasket:$button_background;
$color_addbasket:$button_color;
$background_addbasket_hover:$button_background_hover;
$color_addbasket_hover:$button_color_hover;

/* Template Panier */
$panier-background-color:#fff;
$panier-titre-color:#000;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

/* Flux panier */
$flux-panier_color-shadow:black;

@import "design/784series/declinaison";